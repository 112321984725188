import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export function LoginSystem() {
  const [isUploading, setIsUploading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const profileImageUser = localStorage.getItem("profile_user");
  const LocalStorageUserLoggedData = localStorage.getItem("194aa3da_username");
  const LocalStoragePasswordLoggedData = localStorage.getItem("194aa3da_password");

  // Function to check if "redirectBack" is in the URL
  function shouldRedirectBack() {
    const urlParams = new URLSearchParams(window.location.search);
    const isRedirectBack = urlParams.get("redirectBack") === "true";
    console.log("Should redirect back:", isRedirectBack); // Logs true or false based on URL parameter
    return isRedirectBack;
  }

  // Function to handle redirection or closing the window after login
  function handlePostLoginRedirect() {
    if (shouldRedirectBack()) {
      window.close(); // Close the current tab if "redirectBack" is true
    } else {
      window.location.replace("/home"); // Otherwise, redirect to "/home"
    }
  }

  function handleValidatePermission(e) {
    setIsUploading(true);
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API}/auth/login`, {
        uname: username.trim(),
        password: password,
      })
      .then((res) => {
        console.log(res.data);
        const uidOfUser = res.data.uid;
        localStorage.setItem("194aa3da_username", username);
        localStorage.setItem("194aa3da_password", password);
        localStorage.setItem("uidOfUser", uidOfUser);
        const token = res.data.token;
        Cookies.set("jwt", token);
        localStorage.setItem("token", token);
        setTimeout(() => {
          setIsUploading(false); // This will execute after 3000 milliseconds (3 seconds)
          handlePostLoginRedirect();
        }, 3000);
      })
      .catch((err) => {
        setIsUploading(false);
        Swal.fire("เกิดข้อผิดพลาด!", `${err.response.data.Error}`, "warning");
      });
  }

  function sendLocalUserData(e) {
    e.preventDefault();
    setIsUploading(true);

    localStorage.setItem("194aa3da_username", LocalStorageUserLoggedData);
    localStorage.setItem("194aa3da_password", LocalStoragePasswordLoggedData);

    axios
      .post(`${process.env.REACT_APP_API}/auth/login`, {
        uname: LocalStorageUserLoggedData,
        password: LocalStoragePasswordLoggedData,
      })
      .then((res) => {
        const token = res.data.token;
        const uidOfUser = res.data.uid;

        Cookies.set("jwt", token);
        localStorage.setItem("token", token);
        localStorage.setItem("uidOfUser", uidOfUser);
        setTimeout(() => {
          setIsUploading(false); // This will execute after 3000 milliseconds (3 seconds)
          handlePostLoginRedirect();
        }, 3000);
      })
      .catch((err) => {
        setIsUploading(false);
        Swal.fire("เกิดข้อผิดพลาด!", `${err.response.data.Error}`, "warning");
      });
  }

  return (
    <main
      className="w-full h-screen bg-cover bg-center flex items-center justify-center"
      style={{
        backgroundImage:
          "url('https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&w=1920&h=1060&dpr=1')",
      }}
    >
      <motion.div
        className="bg-white bg-opacity-60 backdrop-filter w-full max-w-4xl p-8 rounded-lg shadow-lg flex flex-col md:flex-row"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 2, ease: "easeInOut" }}
      >
        <div className="md:w-1/2 flex flex-col justify-center p-4 md:p-8">
          <h1 className="text-4xl font-bold text-center text-blue-500">PostPerty.com</h1>
          <h3 className="text-lg md:text-2xl mt-4 text-center">
            แพลตฟอร์มซื้อขายอสังหาฯ บนแผนที่ ดูบ้านแบบ 3D ช่วยชวนช่วยแชร์มีค่าคอม
          </h3>
        </div>
        <div className="md:w-1/2 flex flex-col justify-center p-4 md:p-8">
          <div className="flex flex-col items-center">
            <h1 className="text-2xl text-center text-blue-500 mb-4">
              ยินดีต้อนรับ {isUploading ? <BeatLoader color="#1D4ED8" /> : LocalStorageUserLoggedData}
            </h1>
            {profileImageUser && (
              <div
                className="w-24 h-24 bg-white rounded-full overflow-hidden cursor-pointer"
                onClick={sendLocalUserData}
              >
                <img
                  src={profileImageUser || "https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official.png"}
                  className="w-full h-full object-cover"
                  alt="User Profile"
                />
              </div>
            )}
          </div>
          <form className="mt-8 space-y-4" onSubmit={handleValidatePermission}>
            <div>
              <label className="block text-gray-700">ชื่อผู้ใช้งาน (username)</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="username"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="relative">
              <label className="block text-gray-700">รหัสผ่าน (password)</label>
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="password"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-8 transform"
              >
                <img
                  src={
                    showPassword
                      ? "https://cdn-icons-png.flaticon.com/512/2767/2767194.png"
                      : "https://cdn-icons-png.flaticon.com/512/2767/2767146.png"
                  }
                  alt="Toggle Password Visibility"
                  className="w-6 h-6"
                />
              </button>
            </div>
            <div className="flex justify-between items-center mt-4">
              <Link to="/reset_password" className="text-blue-500 underline">
                ถ้าคุณลืมรหัสผ่าน คลิกที่นี่
              </Link>
            </div>
            <button
              type="submit"
              className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              เข้าสู่ระบบ
            </button>
            <Link to="/register">
              <div className="w-full py-2 bg-yellow-500 text-white text-center rounded-lg hover:bg-yellow-700 transition-colors mt-4">
                สมัครสมาชิก
              </div>
            </Link>
          </form>
        </div>
      </motion.div>
    </main>
  );
}
