import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = "pk.eyJ1IjoicG9zdHBlcnR5IiwiYSI6ImNtNmZycng5bjA4am0ybHE3ZjZrdWR6cDYifQ.BMyXxP2Oy-zJX5O2N7L0Jw";

const MapWithMarkers = ({ markers }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [100.493, 13.7563],
      zoom: 12,
    });

    map.current.addControl(new mapboxgl.NavigationControl());

    markers.forEach((marker) => {
      new mapboxgl.Marker()
        .setLngLat([marker.location.lng, marker.location.lat])
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }).setHTML(
            `<h4>Type: ${marker.type}</h4><p>ID: ${marker.id}</p>`
          )
        )
        .addTo(map.current);
    });
  }, [markers]);

  return <div ref={mapContainer} style={{ width: "100%", height: "100vh" }} />;
};

export default function App() {
  const markers = [
    {
      location: { lat: 13.8544233, lng: 100.4318811 },
      type: "house",
      id: "LEAD-1400529",
    },
    {
      location: { lat: 13.8526788, lng: 100.4341316 },
      type: "house",
      id: "LEAD-4374816",
    },
  ];

  return <MapWithMarkers markers={markers} />;
}
