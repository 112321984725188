import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Rating from "@mui/material/Rating";
import Swal from "sweetalert2";
import axios from "axios";
import { motion } from "framer-motion";
import Cookies from "js-cookie";

const CommentModal = ({ isOpen, onRequestClose, onSubmit, assetId }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const checkLogin = () => {
    const session_id = Cookies.get("jwt");
    return session_id;
  };

  useEffect(() => {
    if (isOpen && assetId) {
      console.log("Fetching comments for assetId:", assetId);
      fetchComments();
    }
  }, [isOpen, assetId]);
  
  
  const fetchComments = async () => {
    setLoading(true);
    setError(null); // Reset error state before making the API call
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API}/event/get-comment?page=1&asset_id=${assetId}`,
            {
                headers: {
                    Authorization: `Bearer ${checkLogin()}`,
                },
            }
        );

        if (response.data && response.data.length > 0) {
            setComments(response.data);
        } else {
            setComments([]); // No comments found, set an empty array
        }
    } catch (error) {
        console.error("Failed to fetch comments", error.response || error); 
        setError("ยังไม่มีคอมเม้นท์"); // Set error message if the request fails
    } finally {
        setLoading(false);
    }
};

  

const handleSubmit = () => {
  if (!rating) return Swal.fire("ข้อผิดพลาด", "กรุณาให้คะแนนระหว่าง 1 ถึง 5 ดาว", "error");
  if (!comment.length) return Swal.fire("", "กรุณาระบุเนื้อหา", "error");
  if (comment.length > 2048) return Swal.fire("Error", "ตัวอักษรสูงสุด 2048 อักษร.", "error");
  onSubmit({ rating, comment });
  onRequestClose();
};


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Comment Modal"
      className="fixed inset-0 z-50 flex items-center justify-center outline-none focus:outline-none"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
    >
      <motion.div
        className="relative w-11/12 mx-auto my-6  max-w-3xl"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.3 }}
      >
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6">
          <h2 className="text-xl font-bold mb-4">เขียนคอมเม้นท์</h2>
          <div className="mb-4">
            <Rating
              name="rating"
              value={rating}
              onChange={(event, newValue) => setRating(newValue)}
            />
          </div>
          <textarea
            className="w-full p-2 border rounded"
            rows="5"
            placeholder="แสดงความคิดเห็นของคุณในเชิงสร้างสรรค์และมีประโยชน์ (ไม่เกิน 2048 ตัวอักขระ)..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <div className="mt-4 flex justify-end space-x-2">
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
              onClick={onRequestClose}
            >
              Cancel
            </button>
            <motion.button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
              onClick={handleSubmit}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Submit
            </motion.button>
          </div>

          <h2 className="text-xl font-bold mt-8 mb-4">ความคิดเห็น</h2>
          {loading ? (
            <p>Loading comments...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : comments.length === 0 ? (
            <p>No comments yet.</p>
          ) : (
            <div className="space-y-4 max-h-64 overflow-y-auto">
              {comments.map((commentData, index) => (
                <div key={index} className="p-4 border rounded shadow-sm bg-gray-50">
                  <div className="flex items-center mb-2">
                    <img
                      src={commentData.user.profile_image}
                      alt={`${commentData.user.username} profile`}
                      className="w-10 h-10 rounded-full mr-4"
                    />
                    <div>
                      <p className="font-bold">{commentData.user.username}</p>
                      <p className="text-sm text-gray-500">{commentData.user.role}</p>
                    </div>
                  </div>
                  <div className="flex items-center mb-2">
                    <Rating name="read-only" value={commentData.rating} readOnly />
                    <span className="ml-2 text-gray-600">{commentData.rating} stars</span>
                  </div>
                  <p className="text-gray-700">{commentData.content}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </motion.div>
    </Modal>
  );
};

export default CommentModal;
